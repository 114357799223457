import React from "react";
import Layout from "../components/layout"
import Blocks from "./blocks/Blocks";
import SEO from "../components/seo";

const Page = ({ pageContext, location }) => {
    const { page, media, articles, achievements, opinions } = pageContext;
    return (
        <Layout ariane={page.ariane}>
            <SEO titleSEO={page.titleSEO} description={page.description} />
            <div id="page">
                <Blocks page={page} media={media} articles={articles} achievements={achievements} opinions={opinions} />
            </div>
        </Layout>
    )
}

export default Page;